import { certificates } from "../assets/Certificates/certificates";

export const Certifications = [
  {
    name: "name",
    img: certificates.udemyDevOps,
    url: `https://www.udemy.com/certificate/UC-6666066d-6a5e-47ff-8b5b-1d4b5d65fd9e/`
  },
  {
    name: "name",
    img: certificates.udemyReact,
    url: `https://www.udemy.com/certificate/UC-5d8eff9c-3db1-42e7-a876-3805d9bf5891/`,
  },
  {
    name: "name",
    img: certificates.udemyNode,
    url: `https://www.udemy.com/certificate/UC-67aacbd8-fb15-4d30-92c8-6c48cfd01a76/`,
  },
  {
    name: "name",
    img: certificates.udemyBootcamp,
    url: `https://www.udemy.com/certificate/UC-47d18b9b-d8cb-4fed-ac06-2c1b61809184/`,
  },

  {
    name: "name",
    img: certificates.softuniBasics,
    url: `https://softuni.bg/certificates/details/112724/d6c6a2fd`,
  },
  {
    name: "name",
    img: certificates.softuniFundamentals,
    url: `https://softuni.bg/certificates/details/141482/a54c6c57`,
    
  },
  { name: "name", img: certificates.diyancontainersandcloud, url: `https://softuni.bg/certificates/details/224782/36c30466` },

  { name: "name", img: certificates.diyanresponsiveweb, url: `` },

  { name: "name", img: certificates.diyanfccalgorithms, url: `` },
];
