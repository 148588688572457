import verius from "./verius-web3.png";
import reactfood from "./food-app.png";
import invaders from "./invaders-2-0.png";
import nativeNFT from "./native-nft.png";
import expenseTracker from "./expenseTracker.png";
import krypt from "./krypt-pricetracker.png";
import theshoestop from "./theshoestop.png";
export const images = {
  verius,
  expenseTracker,
  reactfood,
  invaders,
  nativeNFT,
  krypt,
  theshoestop,
};
