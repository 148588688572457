import { Header } from "../../components/Layout/Header/Header";
import { Content } from "../../components/Layout/OpeningPage/Content";
export const Home = () => {
  return (
    <div>
      <Header />
      <Content />
    </div>
  );
};
